<template>
    <div class="p-5 border-round-md border-1 border-300 border" style="height: 40rem">
        <HeaderCard :loading="loading" tittle="Quantidade de falta consulta" @onLoad="load" />
        <AppGrafico :conjuntosDeDados="conjuntosDeDados" :dados="dados" class="h-full" keyCategoria="data" tipoIndicador="text" tipoLegenda="row" />
    </div>
</template>
<script>
import 'moment/locale/pt-br';
import { getClientBase } from '@/services/http';
import HeaderCard from '@/pages/relatorios-e-indicadores/components/HeaderCard.vue';
import moment from 'moment-timezone';
import AppGrafico from '@/components/grafico/AppGrafico.vue';

export default {
    components: {
        HeaderCard,
        AppGrafico
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        }
    },
    data() {
        return {
            conjuntosDeDados: [
                {
                    label: 'Solicitação',
                    key: 'quantidadeSolicitacoes',
                    cor: '#36A2EB'
                },
                {
                    label: 'Falta Consulta',
                    key: 'quantidadeFaltaConsulta',
                    keyPorcentagem: 'porcentagemFaltaConsulta',
                    cor: '#FF6384'
                }
            ],
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false
        };
    },
    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/falta-consulta/indice-falta-consulta', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                        })
                    }
                });

                this.dados = data.map((item) => ({
                    ...item,
                    data: this.formatarData(item.data)
                }));
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        },
        formatarData(data) {
            let formato;
            if (this.periodicidade === 'day') formato = 'DD/MM/YY';
            if (this.periodicidade === 'month') formato = 'MMM/YY';
            if (this.periodicidade === 'year') formato = 'YY';
            const dataFormatada = moment.tz(data, 'UTC').locale('pt-br').format(formato);

            return dataFormatada.replace(/^./, dataFormatada[0].toUpperCase());
        }
    }
};
</script>
