<template>
    <div class="flex flex-column h-full">
        <div v-if="tipoLegenda === 'grid'" class="flex flex-column flex-wrap max-h-10rem mb-6">
            <div v-for="(conjunto, index) of props.conjuntosDeDados" :key="index" class="flex align-items-center w-4 p-2 cursor-pointer">
                <div class="mr-2" :style="{ height: '10px', width: '10px', background: conjunto.cor }" />
                <div v-if="isMobile || numerado" class="mr-1">{{ index + 1 }} -</div>
                {{ conjunto.label }}
            </div>
        </div>
        <div v-if="tipoLegenda === 'row'" class="flex flex-wrap justify-content-center max-h-10rem mb-2 mt-1">
            <div
                v-for="(conjunto, index) of legendas"
                :key="index"
                :class="{ 'line-through': conjuntosDesativados.includes(conjunto.key) }"
                class="flex align-items-center p-2 cursor-pointer"
                @click="desativarItem(conjunto.key)"
            >
                <div class="mr-2" :style="{ height: '10px', width: '40px', background: conjunto.cor }" />
                <div v-if="isMobile || numerado" class="mr-1">{{ index + 1 }} -</div>
                {{ conjunto.label }}
            </div>
        </div>
        <div class="h-full">
            <Chart :data="data" :options="opcoes" :plugins="plugins" :type="tipo" class="h-full col" />
            <div v-if="tipoLegenda === 'lateral'" class="flex flex-column mt-4 col"> 
                <div class="flex flex-column mb-2">
                    <span class="text-6xl font-semibold text-800">
                        {{ dados.reduce((soma, item) => soma + +item[conjuntosDeDados[0]?.key], 0) }}
                    </span>
                    <span class="text-base font-medium text-700">Total de exames</span>
                </div>
                <div v-for="(legenda, index) of legendas" :key="index" @click="desativarItem(legenda[conjuntosDeDados[0]?.keyLegenda])">
                    <span class="text-4xl font-semibold text-800">{{ legenda[conjuntosDeDados[0]?.key] }}</span>
                    <span class="text-xl font-semibold text-700 ml-2">{{ legenda.porcentagem }}%</span>
                    <div :class="{ 'line-through': conjuntosDesativados.includes(legenda.key) }" class="flex align-items-center cursor-pointer">
                        <div class="mr-2" :style="{ height: '10px', width: '10px', background: legenda.cor }" />
                        <div class="text-base font-medium text-700 ml-2">
                            <div v-if="isMobile || numerado" class="mr-1">{{ index + 1 }} -</div>
                            {{ legenda[conjuntosDeDados[0]?.keyLegenda] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, defineProps, ref } from 'vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const props = defineProps({
    conjuntosDeDados: {
        type: Array,
        required: true
    },
    dados: {
        type: Array,
        required: true
    },
    keyCategoria: {
        type: String,
        default: 'id'
    },
    numerado: {
        type: Boolean,
        default: false
    },
    tipo: {
        validator: (tipo) => ['bar', 'line', 'doughnut'].includes(tipo),
        default: 'bar'
    },
    tipoLegenda: {
        validator: (tipo) => ['grid', 'row', 'lateral'].includes(tipo),
        default: 'grid'
    },
    tipoIndicador: {
        validator: (tipo) => ['text', 'filled'].includes(tipo) || !tipo,
        required: false
    }
});

const conjuntosDesativados = ref([]);
const plugins = [
    ChartDataLabels,
    {
        beforeInit(chart) {
            const originalFit = chart.legend.fit;

            chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
                this.height += 25;
            };
        }
    }
];

const data = computed(() => {
    return {
        labels: props.dados.map((item) => item[props.keyCategoria]),
        datasets: props.conjuntosDeDados?.map((conjunto) => {
            return {
                label: conjunto.label,
                data: props.dados.map((item) => item[conjunto.key]),
                porcentagem: props.dados.map((item) => +item[conjunto.keyPorcentagem]),
                hidden: conjuntosDesativados.value.includes(conjunto.key),
                backgroundColor: conjunto.cor,
                borderColor: props.tipo === 'doughnut' ? 'white' : conjunto.cor,
                barPercentage: 0.6,
                tension: 0.6
            };
        })
    };
});
const legendas = computed(() => {
    if (['bar', 'line'].includes(props.tipo)) {
        return props.conjuntosDeDados;
    }
    if (['doughnut'].includes(props.tipo)) {
        const total = props.dados.reduce((soma, item) => {
            return soma + +item[props.conjuntosDeDados[0]?.key];
        }, 0);
        return props.dados.map((item) => ({
            ...item,
            porcentagem: (+item[props.conjuntosDeDados[0]?.key] / (total / 100)).toFixed(0)
        }));
    }

    return [];
});
const opcoes = computed(() => ({
    events: [],
    hover: { mode: null },
    maintainAspectRatio: false,
    pointBackgroundColor: '#fff',
    pointRadius: 4,
    borderWidth: ['doughnut', 'line'].includes(props.tipo) ? 1.63 : 0,
    layout: {
        padding: {
            top: 12,
            bottom: 30
        }
    },
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            formatter: (_value, context) => {
                if (!props.tipoIndicador) return '';
                const porcentagem = context.dataset.porcentagem?.[context.dataIndex];
                const quantidade = context.dataset.data?.[context.dataIndex];
                if (Number(porcentagem) || +porcentagem === 0) {
                    return [`${(+porcentagem)?.toFixed(1)}%`, +quantidade.toLocaleString('pt-BR')];
                }
            },
            anchor: 'end',
            align: 'top',
            backgroundColor: props.tipoIndicador === 'filled' ? '#495057' : '',
            borderRadius: 2,
            color: 'white',
            padding: 6,
            labels: {
                value: {
                    color: props.tipoIndicador === 'filled' ? 'white' : '#495057',
                    font: {
                        weight: 500,
                        family: '"Inter", sans-serif'
                    }
                }
            }
        }
    },
    scales: {
        x: {
            grid: {
                display: ['bar', 'line'].includes(props.tipo),
                drawBorder: false
            },
            ticks: {
                display: ['bar', 'line'].includes(props.tipo),
                color: '#495057',
                font: {
                    weight: 500,
                    family: '"Inter", sans-serif'
                }
            }
        },
        y: {
            grid: {
                display: ['bar', 'line'].includes(props.tipo),
                drawBorder: false
            },
            ticks: {
                display: ['bar', 'line'].includes(props.tipo),
                color: '#495057',
                font: {
                    weight: 500,
                    family: '"Inter", sans-serif'
                }
            }
        }
    }
}));
const isMobile = computed(() => {
    return window.innerWidth <= 1300;
});

function desativarItem(key) {
    const index = conjuntosDesativados.value.findIndex((item) => {
        return item === key;
    });

    if (index === -1) conjuntosDesativados.value.push(key);
    else conjuntosDesativados.value.splice(index, 1);
}
</script>
