import moment from 'moment';
import { ref, provide, computed } from 'vue';

export function useFiltro() {
    const hoje = new Date();
    const periodicidade = ref('month');
    const periodo = ref([moment(hoje).subtract(11, 'M').startOf('month').toDate(), hoje]);

    const dataInicio = computed(() => {
        let data = moment.tz(periodo.value?.[0], 'UTC');
        if (periodicidade.value.value === 'month') {
            data = data.startOf('month');
        }
        if (periodicidade.value.value === 'year') {
            data = data.startOf('year');
        }

        return data.toDate();
    });
    const dataFim = computed(() => {
        let data = moment.tz(periodo.value?.[1], 'UTC');
        if (periodicidade.value.value === 'month') {
            data = data.endOf('month');
        }
        if (periodicidade.value.value === 'year') {
            data = data.endOf('year');
        }

        return moment(data).isAfter(hoje, 'day') ? hoje : data.toDate();
    });

    function getFilterSettings() {
        return {
            periodicidade: periodicidade.value.value,
            dataInicio: dataInicio.value,
            dataFim: dataFim.value
        };
    }

    function changeFilterSettings(settings) {
        periodicidade.value = settings.periodicidade;
        // quantidade.value = settings.quantidade;
    }

    provide('getFilterSettings', getFilterSettings);
    provide('changeFilterSettings', changeFilterSettings);

    return {
        periodicidade: periodicidade.value,
        dataInicio: dataInicio.value,
        dataFim: dataFim.value
    };
}
