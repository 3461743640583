<template>
    <div class="p-5 border-round-md border-1 border-300 border">
        <HeaderCard :loading="loading" tittle="Funcionários" @onLoad="load" />
        <DataTable
            :pt="{
                wrapper: { style: { height: 'calc(100% - 40px)' } }
            }"
            :rows="10"
            :totalRecords="total"
            :value="dados"
            class="h-30rem mt-4"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            lazy
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            scroll-height="100%"
            scrollable
            @page="onPage"
            @sort="onSort"
        >
            <template #empty> Nenhum registro encontrado. </template>
            <Column v-for="(coluna, index) in colunas" :key="index" :field="coluna.value" :header="coluna.field" sortable>
                <template #body="slotProps">
                    <span>{{ slotProps.data[coluna.value] }}</span>
                </template>
            </Column>
            <ProgressBar v-if="loading" class="w-full border-noround z-2 absolute" mode="indeterminate" style="height: 4px; top: 40px" />
        </DataTable>
    </div>
</template>

<script>
import { getClientBase } from '@/services/http';
import { useFiltro } from '../../composables/filtro';
import moment from 'moment-timezone';
import formatarNumero from '@/utils/FormatadorNumero';
import HeaderCard from '@/pages/relatorios-e-indicadores/components/HeaderCard.vue';

export default {
    components: { HeaderCard },
    props: {
        customerIds: {
            type: Array,
            required: true
        },
        grupoIds: {
            type: Array,
            required: true
        },
        customerBranchIds: {
            type: Array,
            required: true
        }
    },
    setup() {
        return useFiltro();
    },
    data() {
        return {
            colunas: [
                {
                    field: 'Funcionario',
                    value: 'funcionario'
                },
                {
                    field: 'Tipo do Exame',
                    value: 'tipo'
                },
                {
                    field: 'Data',
                    value: 'data'
                },
                {
                    field: 'Cidade',
                    value: 'cidade'
                }
            ],
            loading: false,
            dados: [],
            page: 1,
            total: 0,
            perPage: 10,
            sortField: null,
            sortOrder: null
        };
    },
    watch: {
        quantidade() {
            this.queryData();
        },
        periodicidade() {
            this.queryData();
        }
    },
    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/falta-consulta/funcionarios', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                        }),
                        paginacao: JSON.stringify({
                            limit: this.perPage,
                            page: this.page,
                            sortField: this.sortField,
                            sortOrder: this.sortOrder
                        })
                    }
                });

                this.dados = data.items.map((item) => ({
                    ...item,
                    cidade: item.unidade
                }));
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }

            this.loading = false;
        },
        onPage(event) {
            this.page = event.page + 1;
            this.perPage = event.rows;
            this.queryData();
        },
        onSort(event) {
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.queryData();
        },
        tratarDado(dado) {
            const tipoData = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(dado);
            if (tipoData) {
                return moment.tz(dado, 'UTC').format('DD/MM/YYYY');
            }
            if (this.formatarValor && !isNaN(+dado)) {
                return formatarNumero(+dado);
            }
            return dado;
        }
    }
};
</script>
