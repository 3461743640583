<template>
    <AppMultiselect
        v-model="value"
        @change="change"
        :service="$service"
        optionLabel="name"
    />
</template>
<script>
import BaseService from '../../../services/BaseService';

export default {
    name: 'MultiSelectUnidadePorUsuario',
    props: {
        modelValue: {
            type: Object
        },
        change: {
            type: Function
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    created() {
        this.$service = new BaseService('/customer/branches/por-usuario');
    }
};
</script>
